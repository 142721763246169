<!--
 * @Author: wxb
 * @Date: 2022-03-23 13:53:51
 * @LastEditors: wxb
 * @LastEditTime: 2022-04-21 16:52:44
 * @Description:
-->
<template>
  <div class="containner">
    <headerNav :activeIdx='9'></headerNav>
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/starCompany' }">明星企业</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: `/starCompanyDetail/${companyDetail.enterpriseId}/${0}`}">{{companyDetail.enterpriseName}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{productInfo.productName}}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="company-item">
        <div class="company-item-logo">
          <img class="logo"
               :src="companyDetail.logo && companyDetail.logo.path ? `/api/company/fileManagementNoLogin/preview?fileName=${companyDetail.logo.name}&filePath=${companyDetail.logo.path}`: nologo" />
        </div>
        <div class="company-item-content">
          <div class="company-title">{{companyDetail.enterpriseName}}</div>
          <div class="company-contacts">
            <div class="contact-item">联系人：<span class="info">{{companyDetail.contacts}}</span></div>
            <div class="contact-item">联系方式：<span class="info">{{companyDetail.contactTelephone}}</span></div>
          </div>
          <div class="company-address">机构地址：<span class="info">{{companyDetail.diZh}}</span></div>
          <div class="company-address">主营：<span class="info">{{companyDetail.mainBusiness}}</span></div>
        </div>
      </div>
      <div class="company-nav">
        <div class="company-nav-item"
             v-for="(item,index) in navList"
             :key="index"
             :class="activeNav===index?'active':''"
             @click="handleClickNav(index)">{{item}}</div>
      </div>
      <div class="company-nav-content">
        <div class="pro-detail">
          <div class="pro-detail-left">
            <div class="pro-detail-left-header">
              <i class="el-icon-collection-tag"></i> 推荐产品
            </div>
            <div class="pro-detail-left-content">
              <div class="recommend-list">
                <div class="recommend-list-item"
                     v-for="(item,index) in recommendList"
                     :key="index"
                     :title="item.productName"
                     @click="handleClickRecommend(item.starProductId,item.enterpriseId)">
                  <span class="recommend-dot"></span>
                  <span class="recommend-name">{{item.productName}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="pro-detail-right">
            <div class="product-info">
              <div class="product-info-left">
                <!-- <el-image class="product-img"
                          fit="cover"
                          :src="productImg"></el-image> -->
                <div class="product-img">
                  <pic-zoom :url="productImg"
                            :scale="3"></pic-zoom>
                </div>
                <div class="product-thumbnail">
                  <div class="product-thumbnail-left"
                       @click="handleClickPrev">
                    <i class="el-icon-arrow-left"></i>
                  </div>
                  <div class="product-thumbnail-box">
                    <ul class="product-thumbnail-box-list"
                        ref="product-thumbnail-box-list"
                        :style="{'width':thboxwidth,'left':thboxleft}">
                      <li v-for="(item,index) in thumbnailList"
                          :key="index"
                          :class="imghoverIndex===index?'img-hover':''"
                          @mouseenter="enterThumbnail(index)">
                        <div class="product-thumbnail-box-list-item">
                          <img :src="item.url">
                          <div class="thumbnail-name">{{item.name}}</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="product-thumbnail-right"
                       @click="handleClickNext">
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
              <div class="product-info-right">
                <div class="product-name">{{productInfo.productName}}</div>
                <div class="product-attr">
                  <div class="product-attr-item">
                    <span class="product-attr-label">产品型号：</span>
                    <span class="product-attr-value">{{productInfo.specifications}}</span>
                  </div>
                  <div class="product-attr-item">
                    <span class="product-attr-label">生产厂家：</span>
                    <span class="product-attr-value">{{productInfo.manufacturer}}</span>
                  </div>
                </div>
                <div class="product-attr">
                  <div class="product-attr-item">
                    <span class="product-attr-label">中文品牌：</span>
                    <span class="product-attr-value">{{productInfo.chineseBrand}}</span>
                  </div>
                  <div class="product-attr-item">
                    <span class="product-attr-label">英文品牌：</span>
                    <span class="product-attr-value">{{productInfo.englishBrand}}</span>
                  </div>
                </div>
                <div class="product-attr">
                  <div class="product-attr-item">
                    <span class="product-attr-label">单位：</span>
                    <span class="product-attr-value">{{productInfo.company}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="product-params">
              <div class="product-params-header">技术参数</div>
              <div class="product-params-content"
                   v-html="productInfo.productDescribe"></div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from '@/components/headerNavBlue'
import footerNav from '@/components/footerNav'
import { getStarEnterpriseByOne, getStarRecommendproductList, getProductByIdInfo } from '@/api/startCompany/index'
import nologo from '@/assets/no-logo.png'
import noproduct from '@/assets/no-product.png'
import PicZoom from 'vue-piczoom'
export default {
  name: 'productDetail',
  components: {
    headerNav,
    footerNav,
    PicZoom
  },
  data() {
    return {
      productId: '',
      companyId: '',
      companyDetail: {},
      navList: ['首页', '解决方案', '主营产品'],
      activeNav: 2,
      recommendList: [],
      productImg: '',
      thumbnailList: [],
      productInfo: {},
      nologo,
      thboxwidth: '406px',
      thboxleft: '0px',
      thshowIndex: 5,
      imghoverIndex: 0
    }
  },
  methods: {
    handleClickNav(i) {
      this.$router.push({
        name: 'starCompanyDetail',
        params: { id: this.companyId, nav: i }
      })
    },
    handleClickPrev() {
      if (this.thshowIndex > 5) {
        this.thshowIndex = this.thshowIndex - 5
        if (this.thshowIndex < 5) {
          this.thshowIndex = 5
        }
        const oldLeft = this.$refs['product-thumbnail-box-list'].offsetLeft
        const newLeft = -(this.thshowIndex - 5) * 81
        this.$refs['product-thumbnail-box-list'].animate([
          { left: oldLeft + 'px' },
          { left: newLeft + 'px' }
        ], {
          duration: 500
        })
        this.thboxleft = -(this.thshowIndex - 5) * 81 + 'px'
      }
    },
    handleClickNext() {
      if (this.thshowIndex < this.thumbnailList.length) {
        this.thshowIndex = this.thshowIndex + 5
        if (this.thshowIndex > this.thumbnailList.length) {
          this.thshowIndex = this.thumbnailList.length
        }
        const oldLeft = this.$refs['product-thumbnail-box-list'].offsetLeft
        const newLeft = -(this.thshowIndex - 5) * 81
        this.$refs['product-thumbnail-box-list'].animate([
          { left: oldLeft + 'px' },
          { left: newLeft + 'px' }
        ], {
          duration: 500
        })
        this.thboxleft = -(this.thshowIndex - 5) * 81 + 'px'
      }
    },
    enterThumbnail(i) {
      this.imghoverIndex = i
      this.productImg = this.thumbnailList[i].url
    },
    // 加载企业信息
    loadCompanyInfo() {
      getStarEnterpriseByOne({ enterpriseId: this.companyId }).then(res => {
        if (res.ok) {
          this.companyDetail = res.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 加载推荐产品
    loadRecommendProduct() {
      getStarRecommendproductList({
        enterpriseId: this.companyId
      }).then(res => {
        if (res.ok) {
          this.recommendList = res.data
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 点击推荐产品
    handleClickRecommend(id, enterpriseId) {
      const routeData = this.$router.resolve({
        name: 'productDetail',
        params: { proid: id, comid: enterpriseId }
      })
      window.open(routeData.href, '_blank')
    },
    loadProductDetail() {
      getProductByIdInfo({
        id: this.productId
      }).then(res => {
        if (res.ok) {
          if (res.data.productDescribe) {
            res.data.productDescribe = res.data.productDescribe.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&').replace(/&quot;/g, '"').replace(/&apos;/g, '\'')
          }
          this.productInfo = res.data
          const productMainGraph = res.data.productMainGraph ? [res.data.productMainGraph] : []
          const productSecondaryGraph = res.data.productSecondaryGraph || []
          const list = [...productMainGraph, ...productSecondaryGraph]
          const allThumbnailList = []
          for (const item of list) {
            const name = item.name.split('.')[0]
            const url = `/api/company/fileManagementNoLogin/preview?fileName=${item.name}&filePath=${item.path}`
            allThumbnailList.push({
              name,
              url
            })
          }
          if (allThumbnailList.length > 0) {
            this.thumbnailList = allThumbnailList
            this.productImg = allThumbnailList[0].url
            if (allThumbnailList.length > 5) {
              this.thboxwidth = 81 * allThumbnailList.length + 'px'
            }
          } else {
            this.productImg = noproduct
          }
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  mounted() {
    this.productId = this.$route.params.proid
    this.companyId = this.$route.params.comid
    this.loadCompanyInfo()
    this.loadRecommendProduct()
    this.loadProductDetail()
  }
}
</script>
<style lang="scss" scoped>
// @import "@/style/wangeditor/style/index.scss";
.content {
  width: 144rem;
  margin: 0 auto;
}

.el-breadcrumb {
  height: 5.4rem;
  line-height: 5.4rem;
}
// 企业基本信息
.company {
  &-item {
    position: relative;
    width: 100%;
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    &-content {
      flex: 1;
      padding-left: 10px;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    &-logo {
      width: 240px;
      background: #ffffff;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;

      .logo {
        width: auto;
        height: 88px;
      }
    }

    .info {
      color: rgba(0, 0, 0, 0.65);
    }
  }
  &-title {
    box-sizing: border-box;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 32px;
    font-weight: 500;
  }

  &-contacts {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 40px;
    font-weight: 400;
    display: flex;
    align-items: center;
    .contact {
      &-item {
        width: 400px;
      }
    }
  }

  &-address {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 40px;
    font-weight: 400;
  }
}

// 导航信息
.company {
  &-nav {
    background: #fafafa;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    &-item {
      padding: 15px 0;
      width: 200px;
      text-align: center;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 16px;
      font-weight: 400;
      cursor: pointer;

      &.active {
        color: #ff9b41;
        border-bottom: 2px solid #ff9b41;
      }
    }

    &-content {
      padding: 10px 0;
    }
  }
}

// 产品信息
.pro-detail {
  display: flex;
  margin-top: 10px;
  &-left {
    width: 220px;

    &-header {
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 18px;
      font-weight: 500;
    }

    &-content {
      .recommend {
        &-list {
          box-sizing: border-box;
          border-right: 1px solid rgba(0, 0, 0, 0.1);
          padding-right: 10px;
          padding-top: 10px;
          &-item {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            line-height: 45px;
            font-weight: 500;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            cursor: pointer;
            display: flex;
            align-items: center;

            &:hover {
              background: #fff5ec;
              color: #ff9b41;
            }
          }
        }

        &-dot {
          display: inline-block;
          width: 6px;
          height: 6px;
          background: #d8d8d8;
          margin-right: 10px;
          margin-left: 10px px;
        }

        &-name {
          display: inline-block;
          white-space: nowrap; /*超出的空白区域不换行*/
          overflow: hidden; /*超出隐藏*/
          text-overflow: ellipsis; /*文本超出显示省略号*/
        }
      }
    }
  }

  &-right {
    flex: 1;
    padding-left: 10px;
    .product-info {
      display: flex;
      &-left {
        width: 452px;

        .product-img {
          width: 452px;
          height: 240px;
          border: 1px solid #ddd;
          /deep/ img {
            height: 100%;
          }
        }

        .product-thumbnail {
          position: relative;
          height: 55px;
          &-left,
          &-right {
            position: absolute;
            width: 20px;
            height: 55px;
            background: #000;
            opacity: 0.5;
            line-height: 55px;
            text-align: center;
            top: 0;
            z-index: 2;
            cursor: pointer;
            i {
              font-size: 14px;
              color: #fff;
            }
          }

          &-left {
            left: 0;
          }

          &-right {
            right: 0;
          }

          &-box {
            position: relative;
            width: 406px;
            height: 55px;
            margin: 0 auto;
            overflow: hidden;
            &-list {
              position: absolute;
              height: 55px;
              top: 0px;
              left: 0px;
              li {
                float: left;
                margin: 0 4px;

                &.img-hover {
                  img {
                    border: 2px solid #e53e41;
                  }
                }
              }
              &-item {
                position: relative;
                width: 73px;
                height: 55px;
                border: 1px solid #ddd;
                cursor: pointer;
                img {
                  object-fit: cover;
                  width: 100%;
                  height: 54px;
                }
                .thumbnail-name {
                  position: absolute;
                  width: 100%;
                  text-align: center;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  background: #000;
                  bottom: 0;
                  opacity: 0.6;
                  color: #fff;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }

      &-right {
        padding-left: 15px;
        flex: 1;
        .product-name {
          font-family: PingFangSC-Medium;
          font-size: 18px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 38px;
          font-weight: 500;
        }

        .product-attr {
          display: flex;
          &-item {
            flex: 1;
            padding: 10px 0;
          }

          &-label {
            display: inline-block;
            width: 70px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.45);
            line-height: 14px;
            font-weight: 400;
          }

          &-value {
            font-family: SFProText-Regular;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            line-height: 14px;
            font-weight: 400;
          }
        }
      }
    }

    .product-params {
      margin-top: 20px;
      &-header {
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 18px;
        font-weight: 500;
        border-left: 6px solid #ff9b41;
        padding-left: 10px;
      }

      &-content {
        margin-top: 20px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px 0;
        font-size: 16px;

        @import "@/style/wangeditor/style/index.scss";
      }
    }
  }
}
</style>
